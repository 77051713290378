import React, { FunctionComponent } from 'react'
import { FeaturePageTemplate, TFeatureItem } from './_featurePageTemplate'
import iaaImg from './imgs/qa/iaa.png'
import manageIll from './imgs/manage/manageIll.png'
import analytics from './imgs/manage/analytics.png'
const items: TFeatureItem[] = [
  {
    title: 'Annotator Consensus',
    description:
      'Want to measure consensus ? Tell LightTag how many annotators should work on each document, we’ll schedule the work automatically and give you reports ',

    img: iaaImg,
  },
  {
    title: 'Automated Task Assigment',
    description:
      'LightTag assigns work to your team automatically. You can specify multiple projects with different annotators and we;ll coordinate it for you. ',
    img: manageIll,
  },
  {
    title: 'Reporting And Analytics',
    description:
      'Get the reports you need, like annotator productivity and performace right now, no data scientist or analyst needed.',
    img: analytics,
  },
]

const ProjectManageemnt: FunctionComponent = () => (
  <FeaturePageTemplate
    header={'Annotation Project Managment'}
    subheader={'Everything You Need To Scale Your Annotation Project'}
    featureItems={items}
    googleDescription={
      "LightTag's Annotation Project Management tool will organize your workforce for you"
    }
    googleKeywords={[
      'Annotator Producitivty',
      'NLP Team management',
      'Crowdsourcing',
    ]}
  />
)
export default ProjectManageemnt
